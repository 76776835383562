var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('screen-grid',[_c('screen-card',{attrs:{"title":_vm.$t('frontOffices.id.algolia.section.algoliaConfiguration.title'),"display":"grid","is-loading":_vm.isLoading || _vm.isUpdating},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('screen-block',{attrs:{"title":_vm.$t('frontOffices.id.algolia.section.algoliaConfiguration.label.algoliaRules'),"icon":"category","has-data":_vm.currentFrontOffice.algoliaRules != null && typeof _vm.currentFrontOffice.algoliaRules === 'object',"ratio":"1-1","is-loading":_vm.isLoading},on:{"click":function($event){return _vm.editSimpleField('algoliaRules', 'json', 'textarea')}},scopedSlots:_vm._u([{key:"data",fn:function(){return [_c('code',[_vm._v(" "+_vm._s(_vm.currentFrontOffice.algoliaRules)+" ")])]},proxy:true}])}),_vm._l((_vm.settingsInformation),function(ref){
var title = ref.title;
var icon = ref.icon;
var objKey = ref.objKey;
var data = ref.data;
var allowedValuesKey = ref.allowedValuesKey;
var options = ref.options;
return _c('div',{key:objKey},[(data != null)?_c('screen-block',{attrs:{"title":title,"icon":icon,"has-data":data != null,"data":data.join('<br />'),"ratio":"1-1","is-loading":_vm.isLoading || _vm.isUpdating},on:{"click":function($event){return _vm.editListSelection(objKey, data, allowedValuesKey, options)}}}):_vm._e()],1)})]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }