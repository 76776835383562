<template>
  <modal
    name="modal-lead-generation-mode"
    :title="title"
    :has-apply="true"
    :is-updating="isUpdating"
    @save="save"
    @closed="closed"
  >
    <template v-slot:container>
      <ui-dropdown
        class="modal-locales__default-locale"
        v-model="modelLeadGenerationForm"
        :options="options"
        :placeholder="label"
        :show-label="false"
        :no-absolute="true"
      />
    </template>
  </modal>
</template>

<script>
import UiDropdown from '@/components/UI/Dropdown.vue'

export default {
  name: 'ModalLocales',
  components: {
    UiDropdown,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    label: {
      type: [String, Object],
      required: true,
    },
    leadGenerationModes: {
      type: Array,
      required: true,
    },
    leadGeneration: {
      type: String,
    },
    isUpdating: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      modelLeadGenerationForm: '',
    }
  },
  mounted() {
    this.modelLeadGenerationForm = this.leadGeneration
  },
  computed: {
    options() {
      return ['', ...this.leadGenerationModes]
    },
  },
  methods: {
    save() {
      this.$emit('save', {
        objKey: {
          leadGeneration: this.modelLeadGenerationForm,
        },
      })
    },
    closed() {
      this.$emit('closed')
    },
  },
}
</script>

<style lang="scss" scoped>
.modal-locales {
  &__default-locale {
    margin-bottom: $gutter-mobile;

    @media (min-width: $screen-sm) {
      margin-bottom: $gutter-tablet;
    }
  }
}
</style>
