var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('screen-grid',[_c('screen-card',{attrs:{"title":_vm.$t('frontOffices.id.content.section.generalData.title'),"display":"grid","is-loading":_vm.isLoading},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('screen-block',{attrs:{"title":_vm.$t('frontOffices.id.content.section.generalData.label.features'),"icon":"category","has-data":Array.isArray(_vm.currentFrontOffice.featureList) && _vm.currentFrontOffice.featureList.length > 0,"ratio":"1-3","is-loading":_vm.isLoading},on:{"click":_vm.editFeatures},scopedSlots:_vm._u([{key:"data",fn:function(){return _vm._l((_vm.currentFrontOffice.featureList),function(feature,idx){return _c('span',{key:("feature-" + idx),staticClass:"front-office-content__feature"},[_vm._v(" "+_vm._s(_vm.getTitle(feature))),(idx < _vm.currentFrontOffice.featureList.length - 1)?[_vm._v(", ")]:_vm._e()],2)})},proxy:true}])}),_c('screen-block',{attrs:{"title":_vm.$t('frontOffices.id.content.section.generalData.label.locationMaxPictures'),"icon":"library_photo","has-data":typeof _vm.currentFrontOffice.locationMaxPictures === 'number',"data":_vm.currentFrontOffice.locationMaxPictures,"ratio":"1-3","is-loading":_vm.isLoading},on:{"click":function($event){return _vm.editSimpleField('locationMaxPictures', 'text', 'number', false, {
            min: 0,
            max: 5,
            step: 1,
          })}}}),_c('screen-block',{attrs:{"title":_vm.$t('frontOffices.id.content.section.generalData.label.hotMaxPictures'),"icon":"library_photo","has-data":typeof _vm.currentFrontOffice.hotMaxPictures === 'number',"data":_vm.currentFrontOffice.hotMaxPictures,"ratio":"1-3","is-loading":_vm.isLoading},on:{"click":function($event){return _vm.editSimpleField('hotMaxPictures', 'text', 'number', false, {
            min: 0,
            max: 5,
            step: 1,
          })}}})]},proxy:true}])}),_c('screen-card',{attrs:{"title":_vm.$t('frontOffices.id.content.section.event.title'),"ratio":"1-2","display":"grid","is-loading":_vm.isLoading},scopedSlots:_vm._u([{key:"body",fn:function(){return [(_vm.isLoading)?_vm._l((2),function(n){return _c('screen-flux',{key:("event-skeleton-" + n),attrs:{"is-loading":_vm.isLoading,"ratio":"1-2"}})}):[_vm._l((_vm.reverse(_vm.currentFrontOffice.event)),function(event,idx){return _c('screen-flux',{key:("event-" + idx),attrs:{"title":event.title,"picture":event.picture,"description":event.description,"link":event.link,"icon":"link","from":event.from,"to":event.to,"locale":_vm.currentFrontOffice.localeCode,"ratio":"1-2"},on:{"click":function($event){return _vm.editMedia('event', event)}}})}),(!_vm.currentFrontOffice.event || _vm.currentFrontOffice.event.length === 0)?_c('div',{staticClass:"front-office-content__no-data",domProps:{"innerHTML":_vm._s(
            _vm.$t('common.label.noDataAlone', {
              locale: _vm.currentFrontOffice.localeName,
            })
          )}}):_vm._e()],_c('div',{staticClass:"front-office-content__actions"},[(_vm.isLoading)?_c('skeleton-input',{attrs:{"width":"228px"}}):_c('ui-button',{attrs:{"button":"primary","variant":"influence","icon":"add_post","label":_vm.$t('frontOffices.id.content.section.event.button.addEvent')},on:{"click":function($event){return _vm.editMedia('event')}}})],1)]},proxy:true}])}),_c('screen-card',{attrs:{"title":_vm.$t('frontOffices.id.content.section.headline.title'),"display":"grid","ratio":"1-2","is-loading":_vm.isLoading},scopedSlots:_vm._u([{key:"body",fn:function(){return [(_vm.isLoading)?_vm._l((3),function(n){return _c('screen-flux',{key:("event-skeleton-" + n),attrs:{"is-loading":_vm.isLoading,"ratio":"1-2"}})}):[_vm._l((_vm.currentFrontOffice.hotPictures),function(hotPicture,idx){return _c('screen-flux',{key:("hot-picture-" + idx),attrs:{"title":hotPicture.name,"picture":hotPicture.picture_url,"description":hotPicture.description,"link":hotPicture.url,"icon":"link","ratio":"1-2"},on:{"click":function($event){return _vm.editHotPicture('hotPictures', hotPicture)}}})}),(!_vm.currentFrontOffice.hotPictures || _vm.currentFrontOffice.hotPictures.length === 0)?_c('div',{staticClass:"front-office-content__no-data",domProps:{"innerHTML":_vm._s(
            _vm.$t('common.label.noDataAlone', {
              locale: _vm.currentFrontOffice.localeName,
            })
          )}}):_vm._e()],_c('div',{staticClass:"front-office-content__actions"},[(_vm.isLoading)?_c('skeleton-input',{attrs:{"width":"228px"}}):_c('ui-button',{attrs:{"button":"primary","variant":"influence","icon":"add_photo","label":_vm.$t('frontOffices.id.content.section.headline.button.addHeadline'),"disabled":_vm.currentFrontOffice.hotPictures &&
            _vm.currentFrontOffice.hotPictures.length >= _vm.currentFrontOffice.hotMaxPictures},on:{"click":function($event){return _vm.editHotPicture('hotPictures')}}})],1)]},proxy:true}])}),_c('screen-card',{attrs:{"title":_vm.$t('frontOffices.id.content.section.photos.title'),"ratio":"1-1","display":"grid","is-loading":_vm.isLoading},scopedSlots:_vm._u([{key:"body",fn:function(){return [(_vm.isLoading)?_vm._l((2),function(n){return _c('screen-flux',{key:("photo-skeleton-" + n),attrs:{"is-loading":_vm.isLoading,"ratio":"1-3"}})}):[_vm._l((_vm.currentFrontOffice.locationPictures),function(picture,idx){return _c('screen-flux',{key:("picture-" + idx),attrs:{"picture":picture,"ratio":"1-3"},on:{"click":function($event){return _vm.editPicture('locationPictures', picture)}}})}),(!_vm.currentFrontOffice.locationPictures || _vm.currentFrontOffice.locationPictures.length === 0)?_c('div',{staticClass:"front-office-content__no-data",domProps:{"innerHTML":_vm._s(
            _vm.$t('common.label.noDataAlone', {
              locale: _vm.currentFrontOffice.localeName,
            })
          )}}):_vm._e()],_c('div',{staticClass:"front-office-content__actions"},[(_vm.isLoading)?_c('skeleton-input',{attrs:{"width":"228px"}}):_c('ui-button',{attrs:{"button":"primary","variant":"influence","icon":"add_photo","label":_vm.$t('frontOffices.id.content.section.photos.button.addPhoto'),"disabled":_vm.currentFrontOffice.locationPictures &&
            _vm.currentFrontOffice.locationPictures.length >= _vm.currentFrontOffice.locationMaxPictures},on:{"click":function($event){return _vm.editPicture('locationPictures')}}})],1)]},proxy:true}])}),_c('screen-card',{staticClass:"front-office-content__SEO",attrs:{"title":_vm.$t('frontOffices.id.content.section.seoContent.title'),"display":"grid","is-loading":_vm.isLoading},scopedSlots:_vm._u([{key:"header-right",fn:function(){return [(_vm.isLoading)?_c('skeleton-line',{attrs:{"height":"18px","width":"32px","margin-left":"16px"}}):_vm._e(),(_vm.currentClient.locationTypes && _vm.currentClient.locationTypes.length > 1)?_c('ui-dropdown',{staticClass:"front-office-content__SEO__dropdown",attrs:{"id":"dropdown-location-type","options":_vm.currentClient.locationTypes,"placeholder":_vm.$t('frontOffices.id.content.section.seoContent.locationType')},model:{value:(_vm.locationType),callback:function ($$v) {_vm.locationType=$$v},expression:"locationType"}}):_vm._e()]},proxy:true},{key:"body",fn:function(){return [_c('screen-block',{attrs:{"title":_vm.$t('frontOffices.id.content.section.seoContent.label.locationDescription'),"icon":"text","has-data":_vm.currentFrontOffice.locationDescription != null &&
          typeof _vm.currentFrontOffice.locationDescription[_vm.locationType] === 'string' &&
          _vm.currentFrontOffice.locationDescription[_vm.locationType] !== '',"data":_vm.currentFrontOffice.locationDescription ? _vm.currentFrontOffice.locationDescription[_vm.locationType] : '',"ratio":"1-3","is-loading":_vm.isLoading},on:{"click":function($event){return _vm.editSeoContent('locationDescription')}}}),_c('screen-block',{attrs:{"title":_vm.$t('frontOffices.id.content.section.seoContent.label.locationMetaDescription', { type: _vm.locationType }),"icon":"text","has-data":_vm.currentFrontOffice.locationMetaDescription != null &&
          typeof _vm.currentFrontOffice.locationMetaDescription[_vm.locationType] === 'string' &&
          _vm.currentFrontOffice.locationMetaDescription[_vm.locationType] !== '',"data":_vm.currentFrontOffice.locationMetaDescription ? _vm.currentFrontOffice.locationMetaDescription[_vm.locationType] : '',"ratio":"1-3","is-loading":_vm.isLoading},on:{"click":function($event){return _vm.editSeoContent('locationMetaDescription')}}}),_c('screen-block',{attrs:{"title":_vm.$t('frontOffices.id.content.section.seoContent.label.locationMetaTitle'),"icon":"text","has-data":_vm.currentFrontOffice.locationMetaTitle != null &&
          typeof _vm.currentFrontOffice.locationMetaTitle[_vm.locationType] === 'string' &&
          _vm.currentFrontOffice.locationMetaTitle[_vm.locationType] !== '',"data":_vm.currentFrontOffice.locationMetaTitle ? _vm.currentFrontOffice.locationMetaTitle[_vm.locationType] : '',"ratio":"1-3","is-loading":_vm.isLoading},on:{"click":function($event){return _vm.editSeoContent('locationMetaTitle')}}})]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }