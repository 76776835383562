<template>
  <screen-grid>
    <screen-card
      :title="$t('frontOffices.id.algolia.section.algoliaConfiguration.title')"
      display="grid"
      :is-loading="isLoading || isUpdating"
    >
      <template v-slot:body>
        <screen-block
          :title="$t('frontOffices.id.algolia.section.algoliaConfiguration.label.algoliaRules')"
          icon="category"
          :has-data="currentFrontOffice.algoliaRules != null && typeof currentFrontOffice.algoliaRules === 'object'"
          ratio="1-1"
          :is-loading="isLoading"
          @click="editSimpleField('algoliaRules', 'json', 'textarea')"
        >
          <template v-slot:data>
            <code>
              {{ currentFrontOffice.algoliaRules }}
            </code>
          </template>
        </screen-block>
        <div
          v-for="{ title, icon, objKey, data, allowedValuesKey, options } in settingsInformation"
          v-bind:key="objKey"
        >
          <screen-block
            v-if="data != null"
            :title="title"
            :icon="icon"
            :has-data="data != null"
            :data="data.join('<br />')"
            ratio="1-1"
            :is-loading="isLoading || isUpdating"
            @click="editListSelection(objKey, data, allowedValuesKey, options)"
          />
        </div>
      </template>
    </screen-card>
  </screen-grid>
</template>

<script>
import ScreenGrid from '@/components/Screen/Grid.vue'
import ScreenCard from '@/components/Screen/Card.vue'
import ScreenBlock from '@/components/Screen/Block.vue'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'FrontOfficeContent',
  components: {
    ScreenCard,
    ScreenBlock,
    ScreenGrid,
  },
  props: {
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    currentFrontOffice: {
      type: Object,
      required: true,
    },
    currentClient: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    isUpdating: false,
    settingsInformation: [],
    settingsInformationIndexes: {
      searchableAttributesIndex: 0,
      attributesForFacetingIndex: 1,
    },
  }),
  async mounted() {
    this.isUpdating = true
    this.settingsInformation = [
      {
        title: this.$t(`frontOffices.id.algolia.section.algoliaConfiguration.label.searchableAttributes`),
        icon: 'search',
        objKey: 'searchableAttributes',
        allowedValuesKey: 'searchable_fields',
        options: null,
        data: null,
      },
      {
        title: this.$t(`frontOffices.id.algolia.section.algoliaConfiguration.label.attributesForFaceting`),
        icon: 'filter_alt',
        objKey: 'attributesForFaceting',
        allowedValuesKey: 'faceting_fields',
        options: ['Searchable', 'Filter only', 'Not searchable'],
        data: null,
      },
    ]
    try {
      await this.getFrontOfficeSettings()
    } finally {
      this.isUpdating = false
    }
  },
  computed: {
    ...mapState({
      currentFrontOfficeSettings: state => state.frontoffice.currentFrontOfficeSettings,
    }),
  },
  watch: {
    currentFrontOfficeSettings: function () {
      const { searchableAttributes, attributesForFaceting } = this.currentFrontOfficeSettings
      const { searchableAttributesIndex, attributesForFacetingIndex } = this.settingsInformationIndexes
      this.settingsInformation[searchableAttributesIndex].data = searchableAttributes
      this.settingsInformation[attributesForFacetingIndex].data = attributesForFaceting
    },
  },
  methods: {
    ...mapActions({
      getFrontOfficeSettings: 'frontoffice/getFrontOfficeSettings',
    }),
    editSimpleField(objKey, type, mode, required = false, number = null, options = []) {
      this.$emit('editSimpleField', {
        objKey,
        parameters: {
          type,
          mode,
          required,
          number,
          options,
        },
      })
    },
    editListSelection(objKey, currentValues, allowedValuesKey, options) {
      this.$emit('editListSelection', {
        objKey,
        parameters: {
          allowedValuesKey,
          currentValues,
          options,
          required: false,
        },
      })
    },
  },
}
</script>
