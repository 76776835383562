<template>
  <modal
    name="modal-list-selection"
    :title="title"
    :has-apply="true"
    :is-updating="isUpdating"
    @save="save"
    @closed="closed"
  >
    <template v-slot:container>
      <ui-dropdown
        v-if="options != null && options.length > 0"
        class="modal-list-selection"
        :value="selectedOption"
        :options="options"
        :placeholder="localizedDropdownTexts.placeholder"
        :dropdown-label="localizedDropdownTexts.label"
        :show-label="true"
        :no-absolute="true"
        @input="setOption"
      />
      <ui-tag
        :value="selectedValues"
        :options="formattedAllowedValues"
        track-by="id"
        :label="objectLabelKey"
        :placeholder="localizedTagTexts.placeholder"
        :tag-label="localizedTagTexts.label"
        :show-label="true"
        :no-absolute="true"
        @input="setTags"
        @select="onSelectTag"
      />
    </template>
  </modal>
</template>

<script>
import UiDropdown from '@/components/UI/Dropdown.vue'
import UiTag from '@/components/UI/Tag.vue'

export default {
  name: 'ModalListSelection',
  components: {
    UiTag,
    UiDropdown,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    objectLabelKey: {
      type: String,
      required: false,
      default: null,
    },
    localizedDropdownTexts: {
      type: Object,
      required: false,
      default: () => ({ placeholder: '', label: '' }),
    },
    localizedTagTexts: {
      type: Object,
      required: false,
      default: () => ({ placeholder: '', label: '' }),
    },
    allowedValues: {
      type: Array,
      required: true,
    },
    currentValues: {
      type: Array,
      required: true,
    },
    isUpdating: {
      type: Boolean,
      required: false,
      default: false,
    },
    options: {
      type: Array,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      selectedOption: this.options?.find(value => value === 'Not searchable') ?? null,
      formattedAllowedValues: this.allowedValues.map((value, index) => ({ id: index, label: value })),
      selectedValues: [],
    }
  },
  created() {
    this.selectedValues = this.currentValues
      .map(value => ({
        id: this.formattedAllowedValues.find(({ label }) => label === this.getValueWithoutOption(value))?.id ?? null,
        label: value,
      }))
      .filter(({ id }) => id != null)
  },
  methods: {
    save() {
      this.$emit(
        'save',
        this.selectedValues.map(value => value.label)
      )
    },
    closed() {
      this.$emit('closed')
    },
    setOption(option) {
      this.selectedOption = option
    },
    setTags(tags) {
      if (this.selectedValues.length > tags.length) this.selectedValues = tags
    },
    getFormattedOption(option) {
      if (option != null && option !== 'Not searchable') {
        const splitOption = option.toLowerCase().split(' ')
        // noinspection UnnecessaryLocalVariableJS
        const formattedOptionIfNecessary =
          splitOption.length === 1
            ? splitOption[0]
            : `${splitOption[0]}${splitOption[1].charAt(0).toUpperCase()}${splitOption[1].slice(1)}`
        return formattedOptionIfNecessary
      }
      return ''
    },
    getValueWithoutOption(value) {
      let withoutParenthesis = value.replace('(', '').replace(')', '')
      return (this.options || []).reduce(
        (ret, option) => ret.replace(this.getFormattedOption(option), ''),
        withoutParenthesis
      )
    },
    onSelectTag({ id, label }) {
      this.selectedValues.push({
        id,
        label:
          this.options != null && this.selectedOption !== 'Not searchable'
            ? `${this.getFormattedOption(this.selectedOption)}(${label})`
            : label,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.modal-list-selection {
  &__dropdown {
    margin-bottom: $gutter-mobile;

    @media (min-width: $screen-sm) {
      margin-bottom: $gutter-tablet;
    }
  }
}
</style>
