<template>
  <screen-grid>
    <screen-card :title="$t('frontOffices.id.tracking.section.gaAndSc.title')" display="grid" :is-loading="isLoading">
      <template v-slot:body>
        <screen-block
          :title="$t('frontOffices.id.tracking.section.gaAndSc.label.gaWebPropertyId')"
          icon="category"
          :has-data="
            typeof currentFrontOffice.gaWebPropertyId === 'string' && currentFrontOffice.gaWebPropertyId !== ''
          "
          :data="currentFrontOffice.gaWebPropertyId"
          ratio="1-3"
          :is-loading="isLoading"
          @click="editSimpleField('gaWebPropertyId', 'text', 'input')"
        />
        <screen-block
          :title="$t('frontOffices.id.tracking.section.gaAndSc.label.gaProfile')"
          icon="category"
          :has-data="typeof currentFrontOffice.gaProfile === 'string' && currentFrontOffice.gaProfile !== ''"
          :data="currentFrontOffice.gaProfile"
          ratio="1-3"
          :is-loading="isLoading"
          @click="editSimpleField('gaProfile', 'text', 'input')"
        />
        <screen-block
          :title="$t('frontOffices.id.tracking.section.gaAndSc.label.gaAccountId')"
          icon="category"
          :has-data="typeof currentFrontOffice.gaAccountId === 'string' && currentFrontOffice.gaAccountId !== ''"
          :data="currentFrontOffice.gaAccountId"
          ratio="1-3"
          :is-loading="isLoading"
          @click="editSimpleField('gaAccountId', 'text', 'input')"
        />
        <screen-block
          :title="$t('frontOffices.id.tracking.section.gaAndSc.label.scProfile')"
          icon="category"
          :has-data="typeof currentFrontOffice.scProfile === 'string' && currentFrontOffice.scProfile !== ''"
          :data="currentFrontOffice.scProfile"
          ratio="1-3"
          :is-loading="isLoading"
          @click="editSimpleField('scProfile', 'text', 'input')"
        />
      </template>
    </screen-card>
  </screen-grid>
</template>

<script>
import ScreenGrid from '@/components/Screen/Grid.vue'
import ScreenCard from '@/components/Screen/Card.vue'
import ScreenBlock from '@/components/Screen/Block.vue'

export default {
  name: 'FrontOfficeContent',
  components: {
    ScreenCard,
    ScreenBlock,
    ScreenGrid,
  },
  props: {
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    currentFrontOffice: {
      type: Object,
      required: true,
    },
    currentClient: {
      type: Object,
      required: true,
    },
  },
  methods: {
    editSimpleField(objKey, type, mode, required = false, number = null, options = []) {
      this.$emit('editSimpleField', {
        objKey,
        parameters: {
          type,
          mode,
          required,
          number,
          options,
        },
      })
    },
  },
}
</script>
