<template>
  <modal :title="title" name="modal-seo" :has-apply="true" :is-updating="isUpdating" @save="save" @closed="closed">
    <template v-slot:container>
      <ui-dropdown
        id="modal-seo"
        class="modal-seo__dropdown"
        v-model="modelType"
        :options="parameters.options"
        :placeholder="label.placeholder"
        :dropdown-label="label.dropdown"
        :show-label="true"
        :max-height="120"
      />
      <ui-textarea v-model.trim="modelData[modelType]" :label="label.description" id="modal-field" />
    </template>
  </modal>
</template>

<script>
import UiDropdown from '@/components/UI/Dropdown.vue'
import UiTextarea from '@/components/UI/Textarea.vue'

export default {
  name: 'ModalSeo',
  components: {
    UiDropdown,
    UiTextarea,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    label: {
      type: [String, Object],
      required: true,
    },
    objKey: {
      type: String,
      required: true,
    },
    data: {
      type: Object,
      required: false,
      default: () => {},
    },
    isUpdating: {
      type: Boolean,
      required: false,
      default: false,
    },
    parameters: {
      type: Object,
      required: false,
      default: () => ({
        type: '',
        options: [],
      }),
    },
  },
  data() {
    return {
      modelData: '',
      modelType: [],
    }
  },
  mounted() {
    this.modelData = { ...this.data }
    this.modelType = this.parameters.type
  },
  methods: {
    save() {
      this.$emit('save', {
        objKey: { [this.objKey]: this.modelData },
      })
    },
    closed() {
      this.$emit('closed')
    },
  },
}
</script>

<style lang="scss" scoped>
.modal-seo {
  &__dropdown {
    margin-bottom: $gutter-mobile;

    @media (min-width: $screen-sm) {
      margin-bottom: $gutter-tablet;
    }
  }
}
</style>
