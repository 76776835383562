const MapLocaleMixin = {
  methods: {
    mapLocale: (locales, localeId) => {
      return {
        localeId: localeId,
        localeName: locales.find(locale => locale.id === localeId)?.name ?? null,
      }
    },
  },
}

export default MapLocaleMixin
