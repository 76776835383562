<template>
  <modal name="modal-locales" :title="title" :has-apply="true" :is-updating="isUpdating" @save="save" @closed="closed">
    <template v-slot:container>
      <ui-dropdown
        class="modal-locales__default-locale"
        v-model="modelDefaultLocale"
        :options="locales"
        label="name"
        track-by="id"
        :placeholder="$t(labelValueFor('placeholder'))"
        :dropdown-label="$t(labelValueFor('dropdown'))"
        :show-label="true"
        :no-absolute="true"
        @input="refreshOtherLocales"
      />
      <ui-tag
        :value="displayedOtherLocales"
        :options="localeOptions"
        label="name"
        track-by="id"
        :placeholder="$t(labelValueFor('placeholder'))"
        :tag-label="$t(labelValueFor('tag'))"
        :show-label="true"
        :no-absolute="true"
        @input="setTags"
      />
    </template>
  </modal>
</template>

<script>
import UiDropdown from '@/components/UI/Dropdown.vue'
import UiTag from '@/components/UI/Tag.vue'
import mapLocaleMixin from '@/mixins/mapLocale.mixin'

export default {
  name: 'ModalLocales',
  components: {
    UiTag,
    UiDropdown,
  },
  mixins: [mapLocaleMixin],
  props: {
    title: {
      type: String,
      required: true,
    },
    label: {
      type: [String, Object],
      required: true,
    },
    locales: {
      type: Array,
      required: true,
    },
    defaultLocale: {
      type: Number,
      required: true,
    },
    otherLocales: {
      type: Array,
      required: false,
      default: () => [],
    },
    isUpdating: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      modelDefaultLocale: {},
      modelOtherLocales: [],
    }
  },
  mounted() {
    this.modelDefaultLocale = {
      id: this.defaultLocale,
      name: this.mapLocale(this.locales, this.defaultLocale).localeName,
    }
    if (Array.isArray(this.otherLocales) && this.otherLocales.length > 0) {
      this.modelOtherLocales = this.otherLocales.map(locale => ({
        id: locale,
        name: this.mapLocale(this.locales, locale).localeName,
      }))
    }
  },
  computed: {
    localeOptions() {
      return this.locales.filter(locale => locale.id !== this.modelDefaultLocale.id)
    },
    displayedOtherLocales() {
      return this.modelOtherLocales.filter(({ name }) => name !== '')
    },
  },
  methods: {
    save() {
      this.$emit('save', {
        objKey: {
          defaultLocaleId: this.modelDefaultLocale.id,
          locales: this.modelOtherLocales.map(locale => locale.id),
          isMultilanguage: this.modelOtherLocales.length > 0,
        },
      })
    },
    closed() {
      this.$emit('closed')
    },
    setTags(tags) {
      this.modelOtherLocales = tags
    },
    refreshOtherLocales() {
      this.modelOtherLocales = this.modelOtherLocales.filter(locale => locale.id !== this.modelDefaultLocale.id)
    },
    labelValueFor(key) {
      return typeof this.label === 'string' ? `${this.label}.${key}` : this.label[key]
    },
  },
}
</script>

<style lang="scss" scoped>
.modal-locales {
  &__default-locale {
    margin-bottom: $gutter-mobile;

    @media (min-width: $screen-sm) {
      margin-bottom: $gutter-tablet;
    }
  }
}
</style>
